const PopupModal = window.PopupModal = {
  settings(options) {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      variableWidth: false,
      arrows: false,
      autoplay: false,
      dots: false,
      fade: true,
      accessibility: true,
      ...options
    }
  },
  init(element) {
    $(element).each(function() {
      const $this = $(this);
      const options = $(this).data('options') || {};
      $this.slick(PopupModal.settings(options));

      $(document).on('keydown', function(e) {
        if (e.keyCode === 37) {
          $this.slick('slickPrev');
        }
        if (e.keyCode === 39) {
          $this.slick('slickNext');
        }
      });
    });
  },
  recreate(element, html) {
    const $element = $(element);
    $element.slick('unslick');
    $element.append(html);
    PopupModal.init(element);
  }
}

document.addEventListener('turbolinks:load', function() {

  PopupModal.init('.js-popup');

  $(document.body).on('click', '.js-lightbox-img', function(e) {
    e.preventDefault();
    const modalIndex = $(this).data('modal-index');
    const $modal = $(`#popup-${modalIndex}`);
    const $popup = $modal.find('.js-popup');

    const initialSlide = $(this).closest('.slick-slide').data('slick-index');
    $popup.slick('slickGoTo', initialSlide);

    $modal.modal('show');
  });

  $(document.body).on('click', '.js-modal-body', function(e) {
    const $modal = $(this).closest('.js-modal-popup');
    $modal.modal('hide');
  });

});

$(document).ready(function() {
  function logVisit() {
    $('.js-popup').slick('unslick');
  }
  document.addEventListener('turbolinks:before-cache', logVisit);
});