document.addEventListener('turbolinks:load', function() {

  $(document.body).on('click', '.js-project-modal', function(e) {
    e.preventDefault();
    const $this = $(this);
    if (!$this.hasClass('disabled')) {
      $.get($this.data('url'));
    }
  });

  $(document.body).on('click', '.js-project-tags-modal', function (e) {
    e.preventDefault();
    $.get(`${$(this).data('url')}/${UploadState.format}`);
  });

});
