// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require("bootstrap")
require("slick-carousel/slick/slick")
require("select2/dist/js/select2")
require("cropit/dist/jquery.cropit")
require("smartwizard/dist/js/jquery.smartWizard")

require("app")
require("loader")
require("context-menu")
require("context-menu-cover-image")
require("gallery")
require("lightbox")
require("hero_gallery")
require("api")
require("upload_overview")
require("profile")
require("work")
require("popup")
require("modal")
require("project-details")
require("scroll")
require("popup_scroll")
require("scroll_library")
require("switch")
require("search")
require("boxes")
require("clipboard")
require("signup")
require("firebaseConfig")
require("tracking_code")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
