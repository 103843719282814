document.addEventListener('turbolinks:load', function() {

  const $contextMenu = $('#context-menu');

  // work context menu
  $(document.body).on('click', '.js-context-menu', function(e) {
    e.preventDefault();

    const $this = $(this);
    const offset = $this.offset();
    const top = offset.top + $this.height() - 10;
    const left = offset.left + Math.abs($this.width()) - Math.abs($contextMenu.width()) - 10;

    const id = $this.data('id');
    $contextMenu.css({
      display: 'block',
      top,
      left
    }).addClass('show');
    $contextMenu.find('.dropdown-item').data('id', id);
    return false;
  });

  // hide menu
  $(document.body).on('click', function() {
    $contextMenu.removeClass('show').hide();
  });

  $('.main-content').scroll(function() {
    $contextMenu.removeClass('show').hide();
  });
});


