const GallerySlider = window.GallerySlider = {
  settings() {
    return {
      slidesToScroll: 1,
      infinite: false,
      variableWidth: true,
      nextArrow: '<i class="icon-right-arrow"></i>',
      prevArrow: '<i class="icon-left-arrow"></i>',
      arrows: true,
      autoplay: false,
      dots: false
    }
  },
  init(element) {
    $(element).each(function() {
        $(this).slick(GallerySlider.settings());
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  GallerySlider.init('.js-gallery-slider');

  const sliderRepOptsold = {
    centerMode: true,
    infinite: false,
    variableWidth: true,
    arrows: true,
    nextArrow: '<i class="icon-right-arrow"></i>',
    prevArrow: '<i class="icon-left-arrow"></i>',
    dots: false,
    autoplay: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    cssEase: 'ease-out',
    speed: 500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          // centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }
      }
    ]
  }


  const sliderRepOpts = {
    centerMode: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    focusOnSelect: true,
    infinite: false,
    variableWidth: true,
    arrows: true,
    speed: 500,
    nextArrow: '<i class="icon-right-arrow"></i>',
    prevArrow: '<i class="icon-left-arrow"></i>',
    cssEase: 'ease-out',
    responsive: [
      {
        breakpoint: 1442,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll:1,
          dots: false,
        }
      },
      {
        breakpoint: 1370,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          variableWidth: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      }
    ]
  }


  const sliderTagsOpts = {
    slidesToScroll: 1,
    slidesToShow: 5,
    focusOnSelect: true,
    infinite: false,
    variableWidth: true,
    arrows: true,
    speed: 500,
    nextArrow: '<i class="icon-right-arrow"></i>',
    prevArrow: '<i class="icon-left-arrow"></i>',
    responsive: [
      {
        breakpoint: 1442,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: false,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          variableWidth: false
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      }
    ]
  }

  $('.js-gallery-slider .slick-arrow').on('click', function() {
    event.stopPropagation();
  });

  $('.js-representation').each(function() {
    $(this).slick(sliderRepOpts);
  });

  $('.js-tags').each(function() {
    $(this).slick(sliderTagsOpts);
  });


  // edit gallery
  $('.js-edit-gallery').on('click', function(e) {
    e.preventDefault();
    const { id, url } = $(this).data();
    let path = url;
    if (id) {
      path = `${url}/${id}/edit`;
    }
    $.get(path);
  });

  // delete gallery
  $('.js-delete-gallery').on('click', function(e) {
    e.preventDefault();
    const { id, url } = $(this).data();
    $.ajax({ url: `${url}/${id}`, type: 'DELETE' }).done(() => {
      Turbolinks.clearCache();
      Turbolinks.visit(url);
    });
  });

  // submit gallery form
  $(document.body).on('click', '.js-save-gallery', function(e) {
    e.preventDefault();
    const $this = $(this);
    $this.attr('disabled', true);

    let resp;
    const $form = $(this).closest('form');
    const url = $form.attr('action');
    const data = $form.serializeArray();
    const { isEdit, redirectUrl } = $form.data();
    if (isEdit) {
      resp = $.ajax({ type: 'PATCH', url, data });
    } else {
      resp = $.post(url, data);
    }
    resp.done(function(data) {
      if (redirectUrl) {
        Turbolinks.visit(redirectUrl);
      }else{
        let source = document.getElementById("add_gallary").innerHTML;
        let galleryRow = Handlebars.compile(source);
        let galleryContainer = $(".js-galleries-list-"+data["id_suffix"]);
        let html = galleryRow(data);
        galleryContainer.prepend(html);
      }
      Modal.close();
    }).always(function() {
      $this.attr('disabled', false);
    })
  });

  $(document).on('show.bs.modal', '.modal', function () {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
      $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
  });

  // change visibility
  $('.js-switch-public').on('change', function(e) {
    e.preventDefault();

    const url = $(this).data('url');
    $.ajax({ url, type: 'PUT', data: { private: !this.checked } });
  });

});


$(document).ready(function() {
  function logVisit() {
    $('.js-gallery-slider').slick('unslick');
    if($(".js-modal-lightbox").hasClass("show")){
      $(".js-modal-lightbox").css("display", "none")
    }
  }
  document.addEventListener('turbolinks:before-cache', logVisit);
});