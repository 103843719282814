document.addEventListener('turbolinks:load', function() {
  const $libraryScroll = $('.js-library-scroll');

  if ($libraryScroll.length) {
    const $mainContent = $('.main-content');
    let lastScrollTop = 0;
    let isLoading = false;
    let isDone = $libraryScroll.data('isDone');

    const scrollLibListener = function () {
      fetchLibDataOnScroll();
      $mainContent.one('scroll', function () { // unbinds itself every time it fires
        fetchLibDataOnScroll();
        setTimeout(scrollLibListener, 500); // rebinds itself after 200ms
      });
    };

    scrollLibListener();

    function fetchLibDataOnScroll() {
      if (isLoading || isDone) { return };
      const scrollTop = $mainContent.scrollTop();
      if (scrollTop == 0 || scrollTop > lastScrollTop) {
        const offsetDown = 380;
        if (scrollTop + $mainContent.height() >= ($libraryScroll.height() - offsetDown)) {
          isLoading = true;
          const { page, url } = $libraryScroll.data();
          const newPage = parseInt(page, 10) + 1;
          LibraryLoader.show();

          let query_string = '';
          if (window.location.search) {
            query_string += window.location.search.replace('?', '&');
          }

          const count = $libraryScroll.find('.slick-slide:last').data('count');
          const countQuery = count ? `&count=${count}` : '';

          $.ajax({
            url: `${url}?page=${newPage}${countQuery}${query_string}`,
            dataType: 'script'
          }).done(function(data) {
            // console.log(data)
            $libraryScroll.data('page', newPage);
            isDone = JSON.parse($libraryScroll.data('isDone'));
          }).fail(function(data) {
            if (data.statusText = 'Not Found') {
              isDone = true;
            }
          }).always(function() {
            isLoading = false;
            LibraryLoader.close();
          });
        }
      }

      lastScrollTop = scrollTop;
    }

  }

});