const LightboxModal = window.LightboxModal = {
  settings(options) {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      variableWidth: false,
      nextArrow: '<i class="icon-right-arrow"></i>',
      prevArrow: '<i class="icon-left-arrow"></i>',
      arrows: true,
      autoplay: false,
      dots: false,
      fade: true,
      accessibility: true,
      ...options
    }
  },
  init(element) {
    $(element).each(function() {
      const $this = $(this);
      const options = $(this).data('options') || {};
      $this.slick(LightboxModal.settings(options));

      $(document).on('keydown', function(e) {
        if (e.keyCode === 37) {
          $this.slick('slickPrev');
        }
        if (e.keyCode === 39) {
          $this.slick('slickNext');
        }
      });
    });
  },
  recreate(element, html) {
    const $element = $(element);
    $element.slick('unslick');
    $element.append(html);
    LightboxModal.init(element);
  }
}

document.addEventListener('turbolinks:load', function() {

  LightboxModal.init('.js-lightbox');

  $(document.body).on('click', '.js-gallery-img', function(e) {
    e.preventDefault();
    const modalIndex = $(this).data('modal-index');
    const $modal = $(`#lightbox-${modalIndex}`);
    const $lightbox = $modal.find('.js-lightbox');
    // console.log('$lightbox:', $lightbox)

    const initialSlide = $(this).closest('.slick-slide').data('slick-index');
    // console.log('initialSlide:', initialSlide)
    
    $lightbox.slick('slickGoTo', initialSlide);
    $modal.modal('show');
    // when modal id has lightbox-4 id, used for top tag popup
    if(modalIndex==4) {
      setTimeout(
      function () {
        $('body').addClass('modal-open')
      }, 500);
    }
  });

});

$(document).ready(function() {
  function logVisit() {
    $('.js-lightbox').slick('unslick');
  }
  document.addEventListener('turbolinks:before-cache', logVisit);
});