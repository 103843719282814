const Modal = window.Modal = {
  init(element = '#ajax-modal') {
    this.$ajaxModal = $(element);
    this.setupEvents();
    return this;
  },

  show(html) {
    this.$ajaxModal.html(html);
    this.$ajaxModal.find('.modal').modal('show');
    return this;
  },

  setupEvents() {
    this.$ajaxModal.find('.modal').on('hidden.bs.modal', () => {
      this.$ajaxModal.empty();
    });
  },

  close() {
    this.$ajaxModal.find('.modal').modal('hide');
    return this;
  },

  replace(html, element = '.modal-body') {
    const $modalBody = this.$ajaxModal.find(element);
    $modalBody.html(html);
    return this;
  }
};