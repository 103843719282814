const Boxes = window.Boxes = {
  resize() {
    let $box;
    $(".js-boxes .boxes-item").each(function() {
      $box = $(this);
      $box.height($box.width());
    });

    const $preview = $(".cropit-preview");
    if ($preview.length) {
      $preview.height($box.height());
      $preview.width($box.width());
    }
  }
}