const RegistrationModal = window.RegistrationModal = {
  addValidateEmailListener(url = 'email_validation') {
    $("input#api_user_email").keyup(debounce (function(event) {
      event.preventDefault();
      let email = $(this).val();
      $.ajax({
        url: `${url}?email=${email}`,
        dataType: 'json',
      }).done(function(data) {
        // console.log(data)
        $("input#api_user_email").addClass('is-valid')
        $("input#api_user_email").removeClass('is-invalid')
        $(".api_user_email .invalid-feedback").remove()
      }).fail(function(data) {
        $("input#api_user_email").removeClass('is-valid')
        $("input#api_user_email").addClass('is-invalid')
        $('.api_user_email .invalid-feedback').remove();
        $("input#api_user_email").after(`<div class='invalid-feedback'>${data.responseJSON[0]}</div>` );
      }).always(function() {
        // isLoading = false;
        // LibraryLoader.close();
      });
    },1000));
  },

  // addValidationJob() {
  //   var selected_option = $("#api_user_type_1 option:selected" ).text();
  //   removeAddRequired(selected_option);
  //   $('#api_user_type_1').on('change', function(e) {
  //     selected_option = $("#api_user_type_1 option:selected" ).text();
  //     removeAddRequired(selected_option);
  //   });
  // },

  // validation for self identity 1 and self identity 2. We can not selected both same.
  addValidationSelfIdentity() {
    $('#api_user_self_identity').on('change', function(e) {
      $('#api_user_self_identity_2 option').prop('disabled', false);
      selected_option_val = $("#api_user_self_identity option:selected" ).val();
      $('#api_user_self_identity_2').val('').trigger('change');
      $("#api_user_self_identity_2 option[value='"+ selected_option_val +"']").prop('disabled', true);
    });
  },

  setRoleValue(hash, e) {
    $('#api_user_website, #api_user_artist_type_1, #api_user_artist_type_2').prop('disabled', false);
    //$('.js-role').prepend('<option selected=""></option>').select2({placeholder: "Select", allowClear: true});
    
    // var data = hash[parseInt($(e.currentTarget).val())]
    var data = hash[parseInt($('#api_user_whoareyou').val())] 

    $('.js-role').select2('destroy').empty().select2({
      data: data,
    });
    
    $('.js-role').prepend('<option selected=""></option>').select2({placeholder: "Select", allowClear: true});
    changeWebsiteRequired(data);
  },

  addWebsiteValidation(hash, e){
    // alert('call role 1');
    $.each(hash, function(key, value){
      if(key == parseInt($('#api_user_whoareyou').val()) ){
        changeWebsiteRequired(value);
      }
    })
  },

  imageCropper(){
    if($('#api_user_self_identity_2').val()==''){
      $('#api_user_self_identity_2').val('null')
    }
    $(document.body).on('click', '.js-profile-submit', function(e) {
      e.preventDefault();
      var $form = $('.js-profile-form')[0];

      var $imageCropper = $('.js-image-editor');
      if ($imageCropper.length) {
        $imageCropper.cropit('exportZoom', 4);
        var imageData = $imageCropper.cropit('export', { type: 'image/jpeg' });
        if (imageData) {
          $('.js-cropped-image').val(imageData);
        }
      }

      Rails.fire($form, 'submit');
    });
  },

  emptyIdentity2(){
    // alert($( "#api_user_self_identity_2 option:selected" ).text());
    if ($('#api_user_self_identity_2').val() == '') {
      $('#api_user_self_identity_2').val('null')
    }
    // $(document.body).on('click', '.js-registration-submit', function(e) {
    //   e.preventDefault();
    // });
  }
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function removeAddRequired(cond, label, text_value){
  if(cond)
  {
    $(label).attr('required', true);
    $(label).parent().find('label').text(text_value + ' *');
  }else {
    $(label).removeAttr('required');
    $(label).parent().find('label').text(text_value);
  }
};

function changeWebsiteRequired(data) {
  $.each(data, function (i, option){
    if(option['id']== parseInt($('#api_user_artist_type_1').val())){
      removeAddRequired(option['allow'], '#api_user_website', 'WEBSITE')
    }
  })
};

document.addEventListener("turbolinks:load", function() {
  RegistrationModal.addValidateEmailListener();
  // RegistrationModal.addValidationJob();
  RegistrationModal.addValidationSelfIdentity();
  window.RegistrationModal.imageCropper()
})
