const PopUpScrollImage = window.PopUpScrollImage = {
  scroll() {
    const $galleryScroll = $('.js-gallery-scroll');

    if ($galleryScroll.length) {
      // const $mainContent = $('.main-content');
      const $mainContent = $('#jsTopTagPopupBody').parent()
      let lastScrollTop = 0;
      let isLoading = false;
      let isDone = $galleryScroll.data('isDone');

      const scrollListener = function () {
        fetchDataOnScroll();
        $mainContent.one('scroll', function () { // unbinds itself every time it fires
          fetchDataOnScroll();
          setTimeout(scrollListener, 500); // rebinds itself after 200ms
        });
      };

      scrollListener();

      function fetchDataOnScroll() {
        if (isLoading || isDone) {
          return
        }
        ;
        const scrollTop = $mainContent.scrollTop();
        if (scrollTop == 0 || scrollTop > lastScrollTop) {
          const offsetDown = 380;
          if (scrollTop + $mainContent.height() >= ($galleryScroll.height() - ($galleryScroll.height() - offsetDown))) {
            isLoading = true;
            const {page, url} = $galleryScroll.data();
            const newPage = parseInt(page, 10) + 1;
            GalleryLoader.show();

            // let query_string = '';
            // if (window.location.search) {
            //   query_string += window.location.search.replace('?', '&');
            // }

            const count = $galleryScroll.find('.slick-slide:last').data('count');
            const countQuery = count ? `&count=${count}` : '';

            $.ajax({
              url: `${url}?page=${newPage}${countQuery}${query_string}`,
              dataType: 'script'
            }).done(function (data) {
              $galleryScroll.data('page', newPage);
              isDone = JSON.parse($galleryScroll.data('isDone'));
            }).fail(function (data) {
              if (data.statusText = 'Not Found') {
                isDone = true;
              }
            }).always(function () {
              isLoading = false;
              GalleryLoader.close();
            });
          }
        }

        lastScrollTop = scrollTop;
      }

    }
  }
}

