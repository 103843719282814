import $ from 'jquery';
import Rails from '@rails/ujs';
import toastr from 'toastr';

window.$ = window.jQuery = $;
window.Rails = Rails;
window.toastr = toastr;

function setStateByCountrySelect(data) {
  if (data.length) {
    $('.js-state-select').select2('enable');
  } else {
    $('.js-state-select').select2('enable', false);
  }
}
window.setStateByCountrySelect = setStateByCountrySelect;

function setMainContentHeight() {
  const $mainContent = $('.js-main-content');
  if ($mainContent.length) {
    const headerHeight = $('.main-header').height();
    let tabHeight = 0;
    if ($(window).width() < 1200 || $(document.body).hasClass('show-filter')) {
      tabHeight = $('.nav-tab').height()
    }
    $mainContent.css('height', `calc(100vh - ${headerHeight + tabHeight}px)`);
  }
}
window.setMainContentHeight = setMainContentHeight;


document.addEventListener('turbolinks:load', function() {

  setMainContentHeight();

  $(window).resize(function() {
    setMainContentHeight();
  });

  $('[data-tooltip="tooltip"]').tooltip();
  $('.modal [data-toggle="tooltip"]').tooltip({
    trigger: 'hover',
    zIndex: 1070
  });

  $('.js-select').each(function() {
    const $this = $(this);
    const options = $this.data('options') || {};
    $this.select2(options);
  });


  $(document.body).on('select2:select', '.js-country-select', function (e) {
    const data = $(e.params.data.element).data('states') || [];
    setStateByCountrySelect(data);
    $('.js-state-select').select2('destroy').empty().select2({ data });
  });

  $('.js-people-tags').each(function() {
    return Api.Helper.peopleAutocomplete(this);
  });

  $('.js-unauthorized').on('click', function(e) {
    e.preventDefault();
    $('.js-modal').modal('show');
  });

  $(".modal.modal-lightbox").click(function(){
    var all_videos = $("video");
    for(var i=0; i< all_videos.length; i++){
      all_videos[i].pause();
    }
  });

});

document.addEventListener('turbolinks:before-cache', function (e) {
  $('.js-select, .js-select-search').data('select2') &&
  $('.js-select, .js-select-search').select2('destroy');

  $('.js-select, .self_identity_select').data('select2') &&
  $('.js-select, .self_identity_select').select2('destroy');
});