document.addEventListener('DOMContentLoaded', function(){
  localStorage.removeItem("last_page")
})
document.addEventListener('turbolinks:load', function() {
  ScrollImage.scroll();
});

const ScrollImage = window.ScrollImage = {
  scroll() {
    var $galleryScroll = $('.js-gallery-scroll');
    if ($galleryScroll.length) {
      const $mainContent = $('.main-content');
      let lastScrollTop = 0;
      let isLoading = false;
      var isDone = $galleryScroll.data('isDone');
      window.pageLoaded = $galleryScroll.data('isDone');
      window.page = localStorage.last_page || $galleryScroll.data('page');
      const scrollListener = function () {
        fetchDataOnScroll();
        $mainContent.one('scroll', function () { // unbinds itself every time it fires
          fetchDataOnScroll();
          setTimeout(scrollListener, 500); // rebinds itself after 200ms
        });
      };

      scrollListener();

      function fetchDataOnScroll() {
        if (isLoading || window.pageLoaded) { return };
        const scrollTop = $mainContent.scrollTop();
        if (scrollTop == 0 || scrollTop > lastScrollTop) {
          const offsetDown = 380;
          if (scrollTop + $mainContent.height() >= ($galleryScroll.height() - ($galleryScroll.height() - offsetDown))) {
            isLoading = true;
            var { page, url } = $galleryScroll.data();
            var newPage = parseInt(window.page, 10) + 1;
            GalleryLoader.show();

            var query_string = $('.js-search-form').serialize();
            // if (window.location.search) {
            //   query_string += window.location.search.replace('?', '&');
            // }

            const count = $galleryScroll.find('.slick-slide:last').data('count');
            const countQuery = count ? `&count=${count}` : '';
            $.ajax({
              url: `${url}?page=${newPage}${countQuery}&${query_string}`,
              dataType: 'script'
            }).done(function(data) {
              $galleryScroll.data('page', newPage);
              isDone = JSON.parse($galleryScroll.data('isDone'));
              window.pageLoaded = JSON.parse($galleryScroll.data('isDone'));
            }).fail(function(data) {
              if (data.statusText = 'Not Found') {
                isDone = true;
                window.pageLoaded = true
              }
            }).always(function() {
              isLoading = false;
              GalleryLoader.close();
            });
          }
        }

        lastScrollTop = scrollTop;
      }

    }
  }
}
