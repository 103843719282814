import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

window.FirebaseConfig = function(apiKey,authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId, measurementId ) {
    const firebaseConfig = {
        apiKey: apiKey,
        authDomain: authDomain,
        databaseURL: databaseURL,
        projectId: projectId,
        storageBucket: storageBucket,
        messagingSenderId: messagingSenderId,
        appId: appId,
        measurementId: measurementId
      };
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    return [analytics, logEvent]
}

