document.addEventListener('turbolinks:load', function() {

  // delete work
  $('.js-work-delete').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);
    const { id, url, redirectUrl } = $this.data();

    AjaxLoader.show('Deleting...');

    $.ajax({ url: `${url}/${id}`, type: 'DELETE' })
      .done(function(data) {
        Turbolinks.visit(redirectUrl);
        AjaxLoader.hide();
      })
      .fail(function(data) {
        // console.log('error', data);
        AjaxLoader.hide();
      });
  });

  // edit work
  $('.js-work-edit').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);
    const { id, url, action } = $this.data();
    $.ajax(`${url}/${id}?redirect_action_name=${action}`);
  });

  // remove from gallery
  $(document.body).on('click', '.js-remove-from-gallery', function(e) {
    e.preventDefault();
    const $this = $(this);
    const { id, redirectUrl } = $this.data();
    const $galleryImage = $(`.js-gallery-img[data-id="${id}"]`);
    const { removeUrl, apiMethod } = $galleryImage.data();
    if (apiMethod === 'delete') {
      $.ajax({ url: removeUrl, type: 'DELETE' }).done(() => {
        Turbolinks.clearCache();
        Turbolinks.visit(redirectUrl);
      });
    } else {
      $.post(removeUrl).done(() => {
        Turbolinks.visit(redirectUrl);
      });
    }

  });

  // Untag the work
  $(document.body).on('click', '.js-untag-work', function(e) {
    e.preventDefault();
    const $this = $(this);
    const { id, url, redirectUrl } = $this.data();

    AjaxLoader.show('Untaging...');

    $.ajax({ url: `${url}/${id}`, type: 'GET' })
        .done(function(data) {
          Turbolinks.visit(redirectUrl);
          AjaxLoader.hide();
        })
        .fail(function(data) {
          // console.log('error', data);
          AjaxLoader.hide();
        });
  });

  // set cover image
  $(document.body).on('click', '.js-set-cover', function(e) {
    e.preventDefault();
    const { id, url } = $(this).data();
    $.post(`${url}/${id}`).fail(function(data) {
      // console.log('error', data);
    });
  });

  // set top tag cover image
  $('.js-work-set-cover').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);
    const { id, tag_id, url } = $this.data();
    $.post(`${url}/${id}?tag_id=${tag_id}`);
  });

  // add works to gallery
  let addToGalleryPage = 1;
  let addToGalleryIsLoaded = false;

  function loadGalleries(url, $galleriesList) {
    const path = `${url}&page=${addToGalleryPage}`
    $.get(path)
      .done((data) => {
        const { notFound } = $galleriesList.data();
        if (notFound) {
          $galleriesList.removeClass('content-loading');
          addToGalleryPage = 1;
          addToGalleryIsLoaded = true;
        } else {
          // hide content loader;
          $galleriesList.removeClass('content-loading');
          addToGalleryPage++;
          if (!addToGalleryIsLoaded) {
            loadGalleries(path, $galleriesList);
          }
        }
      });
  }
  $(document.body).on('click', '.js-dropdown-add-to-gallery', function(e) {
    e.preventDefault();

    addToGalleryIsLoaded = false;

    // empty gallery and show loader
    const $galleriesList = $(this).closest('.dropdown').find('.js-galleries-list');
    $galleriesList.empty();
    $galleriesList.addClass('content-loading');

    const { url } = $(this).data();
    loadGalleries(url, $galleriesList);
  });

  $('.js-form-add-works').on('ajax:success', function() {
    $(this).closest('.dropdown').dropdown('toggle');
    const { redirectUrl } = $(this).data();
    if (redirectUrl) {
      Turbolinks.clearCache();
      Turbolinks.visit(redirectUrl);
    }
  });

  $(document.body).on('click', '.js-dropdown-new-gallery', function(e) {
    e.preventDefault();
    const { url } = $(this).data();
    $.get(url);
  });

});


