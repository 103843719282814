const HeroSlider = window.HeroSlider = {
    settings() {
      return {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        nextArrow: '<i class="icon-right-arrow"></i>',
        prevArrow: '<i class="icon-left-arrow"></i>',
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        fade: true,
        accessibility: true,
        respondTo: 'slider',
        lazyLoad:"progressive",
        cssEase: 'ease-out',
        centerMode: true
      }
    },
    init(element) {
      $(element).each(function() {
        $(this).slick(HeroSlider.settings());
        $(document).on('keydown', function(e) {
          if (e.keyCode === 37) {
            $this.slick('slickPrev');
          }
          if (e.keyCode === 39) {
            $this.slick('slickNext');
          }
        });
      });
    }
  }
  
  document.addEventListener('turbolinks:load', function() {
    
    HeroSlider.init('.js-hero-slider');

    $('.js-hero-slider .slick-arrow').on('click', function() {
      event.stopPropagation();
    });
  
    function playPauseVideo(slick, control){
      var currentSlide, slideType, startTime, video;
    
      currentSlide = slick.find(".slick-current");
      slideType = currentSlide.attr("class").split(" ")[1];
      startTime = currentSlide.data("video-start");
    
      if (slideType === "video") {
        video = currentSlide.children("video").get(0);
        if (video != null) {
          if (control === "play"){
            video.play();
          } else {
            video.pause();
          }
        }
      }
    }

    //  PLAY IF FIRST SLIDE IS VIDEO
    $('.hero-slider').on('init', function(event, slick){
      slick = $(slick.currentTarget);
      setTimeout(function(){
        playPauseVideo(slick,"play");
      }, 1000);
      // console.log('play if first slide is a video!');

      if ($('.slick-current').hasClass('vid_blk')) {
         $('video')[0].play();
          // console.log('play if first slide is a video!');
      }
  });

  //  PLAY/PAUSE IF VIDEO
  $('.hero-slider').on('afterChange', function(event, slick){
      slick = $(slick.$slider);
      playPauseVideo(slick,"play");
      // console.log('play this video slide!');
    
     if ($('.slick-slide.slick-current').hasClass('vid_blk')) {  
        $('#new-campaign').slick('refresh');
       $('#new-campaign .slick-current video')[0].play();
        // console.log('no play this 2nd expression for the video slide!');
      }
  });


  $('.hero-slider').on('beforeChange', function(event, slick){
    slick = $(slick.$slider);
    playPauseVideo(slick,"pause");
    // console.log('pause this video slide!');

    if ($('.slick-slide.slick-current').hasClass('vid_blk')) {
      $('video.vid_blk')[0].pause();
       // console.log('pause this video slide!');
    }
  });

});

$(document).ready(function() {
  function logVisit() {
    $('.js-hero-slider').slick('unslick');
   }
  document.addEventListener('turbolinks:before-cache', logVisit);
});