document.addEventListener("turbolinks:load", function() {
  // show more info on user
  $(".js-moreless-button").on("click", function(e) {
    e.preventDefault();

    const $this = $(this);
    $(".js-more-less").slideToggle();

    if ($this.text() == "+ show more") {
      $this.text("- show less");
    } else {
      $this.text("+ show more");
    }
  });

  // resize work grid
  Boxes.resize();

  $(window).resize(function() {
    Boxes.resize();
  });

  /**
   * trigger click event on file input field
   */
  $(".js-background-replace").on("click", function(event) {
    event.preventDefault();
    $("#background_image_image").trigger("click");
  });

  /**
   * submit form if file input field changes
   */
  $("input#background_image_image").on("change", function(event) {
    event.preventDefault();
    // submit form
    var form = $(".js-background-image-form").get(0)
    Rails.fire(form, 'submit');
    // clear form
    form.reset()
  });

  /**
   * trigger form upload without selected image (delete background image)
   */
  $(".js-background-remove").on("click", function(event) {
    event.preventDefault();
    // submit form
    var form = $(".js-background-image-form").get(0)
    Rails.fire(form, 'submit');
  });


  // upload profile image
  $(document.body).on('click', '.js-profile-image', function(e) {
    e.preventDefault();
    $('.js-profile-image-input').trigger('click');
    return false;
  });

  $(document.body).on('change', '.js-profile-image-input', function(e) {
    const $this = $(this);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {

      getImageSize(e.target.result, function() {
        // validate image size
        if (this.width < 400 || this.height < 400) {
          alert('Too small image\nPlease select a bigger image (minimum 400 pixels by either side).');
          $this.val('');
        } else if (this.width > 10000 || this.height > 10000) {
          alert('Too big image\nMax file size is 10000 pixels per side, please select a smaller file.');
          $this.val('');
        } else {
          $('.js-profile-image').hide();
          setupCropper(e.target.result);
        }
      });
    }
  });

  function setupCropper(image) {
    const $profileGroup = $('.js-profile-image-group');

    $profileGroup.append('<div class="image-editor js-image-editor"><div class="cropit-preview"></div></div>');

    const width = 100;
    const height = 100;

    const $cropper = $('.js-image-editor');
    const $preview = $cropper.find('.cropit-preview');
    $cropper.css('width', width);
    $cropper.css('height', height);
    $preview.css('width', width);
    $preview.css('height', height);

    $cropper.cropit({
      originalSize: false,
      allowDragNDrop: false,
      smallImage: 'stretch',
      imageState: {
        src: image,
      },
    });
  }

});
