document.addEventListener('turbolinks:load', function() {

  const $contextMenuCoverImg = $('#context-menu-cover-image');

  // work context menu
  $(document.body).on('click', '.js-context-menu-cover-image', function(e) {
    e.preventDefault();

    const $this = $(this);
    const offset = $this.offset();
    const top = offset.top + $this.height() - 10;
    const left = offset.left + Math.abs($this.width()) - Math.abs($contextMenuCoverImg.width()) - 10;

    const id = $this.data('id');
    const tagId = $this.data('tag-id');
    $contextMenuCoverImg.css({
      display: 'block',
      top,
      left,
      'z-index': 10408
    }).addClass('show');
    $contextMenuCoverImg.find('.dropdown-item').data('id', id).data('tag_id', tagId);
    return false;
  });

  // hide menu
  $(document.body).on('click', function() {
    $contextMenuCoverImg.removeClass('show').hide();
  });

  $('.main-content').scroll(function() {
    $contextMenuCoverImg.removeClass('show').hide();
  });
});


