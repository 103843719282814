const GalleryLoader = window.GalleryLoader = {
  show(element = '.js-gallery-scroll') {
    $(element).append('<div id="gallery-loader"></div>');
  },

  close() {
    $('#gallery-loader').remove();
  }
}

const LibraryLoader = window.LibraryLoader = {
  show(element = '.js-library-scroll') {
    $(element).append('<div id="library-loader"></div>');
  },

  close() {
    $('#library-loader').remove();
  }
}

document.addEventListener('turbolinks:load', function() {

  const AjaxLoader = window.AjaxLoader = {
    init() {
      this.$ajaxLoader = $('#ajax-loader');
      return this;
    },

    show(text) {
      this.$ajaxLoader.find('.ajax-loader-content').text(text);
      this.$ajaxLoader.show();
      return this;
    },

    hide() {
      this.$ajaxLoader.hide();
      return this;
    }
  };

  AjaxLoader.init();

});