document.addEventListener('turbolinks:load', function() {

  // copy to clipboard
  $('.js-clipboard-copy').on('click', function(e) {
    e.preventDefault();
    const $this = $(this);
    if (!$this.hasClass('disabled')) {
      const clipboardText = $this.closest('.js-clipboard').find('.js-clipboard-text').get(0);
      clipboardText.select();
      clipboardText.setSelectionRange(0, 99999); // used for mobile phone
      document.execCommand('copy');

      toastr.info('Link copied');
    }
  });
});

