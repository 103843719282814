import Selection from "@simonwep/selection-js/dist/selection.min";

function getImageSize(imageURL, callback) {
  const image = new Image();
  image.onload = callback;
  image.src = imageURL;
}
window.getImageSize = getImageSize;

document.addEventListener('turbolinks:load', function() {

  let coordi = null;
  const $image_overview = $('.js-overview-image');

  // submit form
  $('body').on('click' , 'a.js-upload-next', function() {
  // $('#overview-main .js-upload-next').on('click', function() {
    event.preventDefault();
    if (!$image_overview.val()) {
      $image_overview.trigger('click');
    }
  });

  // helpers
  function getCoords(elements) {
    if (!elements.length) { return false };
    const first = elements[0];
    const last = elements[elements.length - 1];
    const $first = $(first);
    const $last = $(last);
    return {
      left_top_x: $first.data('x'),
      left_top_y: $last.data('y'),
      right_bottom_x: $last.data('x'),
      right_bottom_y: $first.data('y'),
    }
  }

  // box width
  function getCssWidth(coordi, width) {
    const left = coordi['left_top_x'] % 3;
    const right = coordi['right_bottom_x'] % 3;
    if (right > left) {
      return `calc(${((right - left) + 1) * width}px + ${((right - left) * 6)}px)`;
    } else {
      return '100%';
    }
  }

  // box height
  function getCssHeight(coordi, width) {
    const top = coordi['right_bottom_y'] - coordi['left_top_y'];
    return `calc(${(top + 1) * width}px + ${(top * 6)}px)`;
  }

  // selection and image upload
  const ovselection = window.selection = new Selection({
    class: 'js-upload-overview-boxes',
    selectables: ['.js-upload-overview-boxes > .boxes-item'],
    singleClick: true,
    boundaries: ['.js-upload-overview-boxes']
  });
  // console.log(ovselection);
  ovselection.on('start', ({ inst, selected }) => {
    for(const el of selected) {
      $(el).removeClass('selected');
      inst.removeFromSelection(el);
    }
    inst.clearSelection();
  }).on('move', (args) => {
    const { selected, changed: { removed, added } } = args;

    // 12 is max selection
    if (selected.length > 12) { return };

    for(const el of added) {
      const $el = $(el);
      if ($(el).hasClass('disabled')) { continue; }
      $(el).addClass('selected');
    }

    for(const el of removed) {
      $(el).removeClass('selected');
    }
  }).on('stop', (args) => {
    // we have image return
    if ($image_overview.val()) { return; }

    const { selected, inst } = args;

    const enabled = selected.filter(item => !$(item).hasClass('disabled'));

    // set form values
    coordi = getCoords(enabled);
    // console.log(coordi);
    if (coordi) {
      setCoordsFormValues();
      // enable next button
      $('.js-update-overview').removeClass('disabled');
    } else {
      $('.js-update-overview').addClass('disabled');
    }
    inst.keepSelection();
  });

  function setCoordsFormValues() {
    for (const key in coordi) {
      $(`.js-coordi-${key}`).val(coordi[key]);
    }
  }

  function setupImage(image) {
    const $OvFirstBox = $('.js-upload-overview-boxes .boxes-item.selected .js-rendar-img').first();
    const OvCssWidth = getCssWidth(coordi, $OvFirstBox.width());
    const OvCssHeight = getCssHeight(coordi, $OvFirstBox.width());

    $OvFirstBox.append('<div class="image-editor js-image-editor"><div class="cropit-preview"></div></div>');

    const $ovcropper = $('.js-image-editor');
    const $ovpreview = $ovcropper.find('.cropit-preview');
    $ovcropper.css('width', '100%');
    $ovcropper.css('height', '100%');
    $ovpreview.css('width', '100%');
    $ovpreview.css('height', '100%');

    $ovcropper.cropit({
      backgroundImage: true,
      originalSize: false,
      allowDragNDrop: false,
      smallImage: 'stretch',
      imageState: {
        src: image,
      },
      onOffsetChange: setCropFormValues
    });

    setDefaultCropValues();
  }

  function setDefaultCropValues() {
    const imageSize = $('.js-image-editor').cropit('imageSize');
    $('.js-crop_coordinates').val(`[0,0,${imageSize.width},${imageSize.height}]`);
    $('.js-rotate_angle').val(0);
  }

  function setCropFormValues() {
    const $ovcropper = $('.js-image-editor');

    const offset = $ovcropper.cropit('offset');
    const zoom = $ovcropper.cropit('zoom');
    const imageSize = $ovcropper.cropit('imageSize');

    const exportZoom = 1 / zoom;
    const x = Math.abs(Math.floor(offset.x * exportZoom));
    const y = Math.abs(Math.floor(offset.y * exportZoom));

    $('.js-crop_coordinates').val(`[${x},${y},${imageSize.width},${imageSize.height}]`);
  }

  $image_overview.on('change', function(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (readerEvent) => {
      getImageSize(readerEvent.target.result, function() {
        // validate image size
        if (this.width < 400 || this.height < 400) {
          alert('Too small image\nPlease select a bigger image (minimum 400 pixels by either side).');
          $image_overview.val('');
        } else if (this.width > 10000 || this.height > 10000) {
          alert('Too big image\nMax file size is 10000 pixels per side, please select a smaller file.');
          $image_overview.val('');
        } else {
          setupImage(readerEvent.target.result);

          // preventing selections after the image has been selected
          $('.js-upload-overview-boxes .boxes-item').addClass('disabled');
          $('.jjs-upload-overview-boxes .boxes-item.selected').removeClass('selected');

          $.get($('.js-upload-next').data('url'));
          AjaxLoader.show('Please wait...');
        }
      });
    }
  });
});
