const Search = window.Search = {
  getSelectedTagsValues($searchTags, tags) {
    const result = [];
    const ids = $searchTags.data('selected') || [];
    ids.forEach(id => {
      tags.forEach((gruop) => {
        gruop.children.forEach((item) => {
          if (item.id === id) {
            result.push(item);
          }
        });
      });
    });
    return result;
  },

  populateSearchTags($searchTags) {
    const assetTypes = this.getArtistAssetTypesSelection();
    const data = this.filterSearchTags(searchTags, assetTypes);
    $searchTags.select2('destroy').empty().select2({ data: data });
    return data;
  },

  getArtistAssetTypesSelection() {
    let assetTypes = [];
    $('.js-search-artist-types').find(':selected').each(function() {
      assetTypes = assetTypes.concat($(this).data('asset-types'));
    });
    return assetTypes;
  },

  filterSearchTags(tags, assetTypes) {
    if (!assetTypes.length) { return tags; }
    const result = [];
    tags.forEach((gruop) => {
      const children = gruop.children.filter((item) => {
        return item.parent.some((tag) => { return assetTypes.includes(tag) });
      });
      if (children.length) {
        result.push({ id: gruop.id, text: gruop.text, children: children });
      }
    });
    return result;
  },

  tagsAutocomplete($searchTags, searchTags) {
    $searchTags.select2({
      data: searchTags,
      closeOnSelect: false
    });
  }
}

document.addEventListener('turbolinks:load', function() {

  $('.js-search-filter').on('click', function(e) {
    e.preventDefault();

    const $body = $(document.body);
    $body.toggleClass('show-filter');

    const $this = $(this);
    $this.toggleClass('active');

    const $navbar = $this.closest('.navbar');
    $navbar.toggleClass('navbar-expand-search');

    setMainContentHeight();
  });

  $('.js-search-save').on('click', function(e) {
    e.preventDefault();
    const { url } = $(this).data();
    const tags = $('.js-search-tags').val();
    const query = tags.length ? `&${tags.map(tag => `tags[]=${tag}`).join('&')}` : '';
    $.get(`${url}${query}`);
  });

  if (typeof(searchTags) !== 'undefined') {
    const $searchTags = $('.js-search-tags');
    Search.tagsAutocomplete($searchTags, searchTags);

    const tags = Search.populateSearchTags($searchTags);
    const tagValues = Search.getSelectedTagsValues($searchTags, tags);

    Api.Helper.selectedOptionsFromValues($searchTags, tagValues);

    $('.js-search-artist-types').on('select2:select', function () {
      Search.populateSearchTags($searchTags);
    });

    $('.js-search-artist-types').on('select2:unselect', function () {
      Search.populateSearchTags($searchTags);
    });

    function submitForm(form){
      AjaxLoader.show('Please wait...');
      form.find('input[type="submit"]').click();
    }

    $('.js-select-search').on('select2:select', function () {
      submitForm($(this).closest('form'))
    });

    $('.js-select-search').on('select2:unselect', function () {
      submitForm($(this).closest('form'))
    });

    $('.self_identity_select').on('select2:select', function () {
      submitForm($(this).closest('form'))
    });

    $('.self_identity_select').on('select2:unselect', function () {
      $(this).data('state', 'unselected');
      submitForm($(this).closest('form'))
    }).on("select2:open", function(e) {
      if ($(this).data('state') === 'unselected') {
        $(this).removeData('state');

        var self = $(this);
        setTimeout(function() {
          self.select2('close');
        }, 1);
      }
    });

    $(".self_identity_select").on('select2:select', function () {
      submitForm($(this).closest('form'))
    });


    $('.js-checkbox-search').on('change', function() {
      submitForm($(this).closest('form'))
    });
  }

});