document.addEventListener('turbolinks:load', function() {

  $(document.body).on('change', '.js-switch', function() {
    $(this).closest('.toggle-switch').toggleClass('active').find('.js-switch-title').toggleClass('active');
  });

  $(document.body).on('click', '.js-switch-title, .js-switch-label', function(e) {
    e.preventDefault();
    $(this).closest('.toggle-switch').find('.js-switch').trigger('click');
  });

  $(document.body).on('change', '.js-switch-overview', function() {
    $(this).closest('.toggle-switch-overview').toggleClass('active').find('.js-switch-title-overview').toggleClass('active');
  });

  $(document.body).on('click', '.js-switch-title-overview, .js-switch-label-overview', function(e) {
    e.preventDefault();
    $(this).closest('.toggle-switch-overview').find('.js-switch-overview').trigger('click');
  });

  function upperFirstLetter(str) {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  }

  // like, unlike, follow, unfollow
  $(document.body).on('click', '.js-switch-action', function(e) {
    e.preventDefault();
    const $this = $(this);
    const isActive = $this.hasClass('active');

    $.post(isActive ? $this.data('inactive') : $this.data('active'))
      .done((data) => {
        if (!isActive) {
          $this.addClass('active');
          const title = $this.data('inactive');
          const labelText =  $this.data('inactiveLabel');
          if (labelText) {
            $this.children().first().text(labelText);
            $this.attr('title', '');
          } else {
            $this.attr('title', upperFirstLetter(title.substr(title.lastIndexOf('/') + 1)));
          }
        } else {
          $this.removeClass('active');
          const title = $this.data('active');
          const labelText = $this.data('activeLabel');
          if (labelText) {
            $this.children().first().text(labelText);
            $this.attr('title', '');
          } else {
            $this.attr('title', upperFirstLetter(title.substr(title.lastIndexOf('/') + 1)));
          }
        }
        $this.tooltip('dispose').tooltip();
      });
    }
  );

});