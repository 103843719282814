const Api = window.Api = {};

Api.Helper = {
  getNameByAttrId(element) {
    const attr = $(element).attr('id');
    return attr.replace('upload_', '');
  },

  mapSelectItem(item) {
    return { id: item.id, text: item.text }
  },

  peopleAutocomplete(element, removeExisting = true) {
    const $this = $(element);
    $this.select2({
      minimumInputLength: 1,
      closeOnSelect: false,
      ajax: {
        url: $this.data('url'),
        dataType: 'json',
        data: function (params) {
          return { query: params.term };
        },
        processResults: function (results) {
          if (removeExisting) {
            results = results.filter(item => {
              return !$this.val().includes(`${item.id}`);
            });
          }
          return { results };
        }
      },
      templateResult: function(tag) {
        if (tag.loading) { return tag.text };
        const html = [];
        html.push('<div class="tag-item">');
        html.push(`<img src=${tag.image} class="tag-img" />`);
        html.push('<div class="tag-text">');
        html.push('<span class="tag-title">' + tag.text + '</span>');
        html.push('<span class="tag-subtitle">' + tag.whoareyou + '</span>');
        html.push('</div>');
        html.push('</div>');
        return $(html.join(''));
      }
    });
    this.selectedOptions($this);
  },
  selectedOptions($element) {
    const values = $element.data('selected');
    if (values) {
      values.forEach(function(tag) {
        const option = new Option(tag.text, tag.id, true, true);
        $element.append(option);
      })
      $element.trigger('change');
      return $element;
    }
  },
  selectedOptionsFromValues($element, values) {
    if (values) {
      values.forEach(function(tag) {
        $(`option[value=${tag.id}]`).attr('selected', 'selected');
      })
      $element.trigger('change');
      return $element;
    }
  },
  locationAutocomplete(element, callback) {
    const autocomplete = new google.maps.places.Autocomplete(element);
    autocomplete.addListener('place_changed', callback.bind(autocomplete));
    return autocomplete;
  },
  handleError(data) {
    const result = '';
    if (data && data.responseJSON) {
      data.responseJSON.forEach(function(msg) {
        result += msg + '\n';
      });
    }
    return result;
  }
};